import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { CollapsibleContainerProps } from './CollapsibleContainer.style.types'

export const CollapsibleContainer = styled.div(
  (props: CollapsibleContainerProps): FlattenSimpleInterpolation => css`
    &:last-child {
      margin-bottom: 0;
    }
  `,
)
