import { css, FlattenSimpleInterpolation } from 'styled-components'

import { typography } from '@vfuk/core-mixins'

import { HeadingTypeKeys } from '@vfuk/core-themes/dist/constants/typography.types'

type HeadingElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5'

const headings = {
  h1: 5,
  h2: 4,
  h3: 3,
  h4: 2,
  h5: 1,
}

// TODO: eslint goes crazy with indent here, not sure how to fix that
/* eslint-disable indent */
const fontSizes = css`
  ${(): FlattenSimpleInterpolation => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const headingStyles = Object.entries(headings).map(
      ([element, size]: [HeadingElement, HeadingTypeKeys]): FlattenSimpleInterpolation => css`
        ${element} {
          ${typography.heading(size, true)};
        }
      `,
    )
    const paragraphStyles = css`
      p {
        ${typography.paragraph(2, true)};
      }
    `
    return css`
      ${headingStyles} ${paragraphStyles}
    `
  }}
` as FlattenSimpleInterpolation
/* eslint-enable indent */

export default fontSizes
