import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { typography } from '@vfuk/core-mixins'

import { CollapsibleContainerBodyProps } from './CollapsibleContainerBody.style.types'

import localThemes from '../../../themes/CollapsibleContainer.theme'

export const CollapsibleContainerBody = styled.div((props: CollapsibleContainerBodyProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  return css`
    overflow: hidden;
    display: none;
    color: ${localTheme.appearance[props.appearance!].color};
    ${typography.paragraph(2, false)};

    ${props.isOpen &&
    css`
      display: block;
    `}

    ${props.isTransitioning &&
    css`
      display: block;
    `}

    ${props.isOpen &&
    !props.isTransitioning &&
    css`
      overflow: visible;
    `}

    ${props.isInverse &&
    css`
      color: ${localTheme.appearance[props.appearance!].inverse.color};
    `}
  `
})
