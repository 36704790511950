import { useEffect, useState } from 'react'

import { useTheme } from 'styled-components'

import { BreakpointTheme, Breakpoints } from '../../MatchMedia.types'

import { breakpointWatcher, addBreakpointCallback, removeBreakpointCallback } from '../breakpointTools'

// FUNCTION HOOK
export const useBreakpoints = (): Breakpoints => {
  const [breakpoints, setBreakpoints] = useState(breakpointWatcher.breakpoints)
  const theme = useTheme() as BreakpointTheme

  useEffect((): (() => void) => {
    addBreakpointCallback(setBreakpoints, theme.breakpoints)
    return (): void => removeBreakpointCallback(setBreakpoints)
  }, [])

  return breakpoints
}
