import React, { PureComponent, ReactNode } from 'react'
import { withTheme } from 'styled-components'

import Icon from '@vfuk/core-icon'

import * as Styled from './styles/CollapsibleContainerHeader.style'

import { CollapsibleContainerHeaderProps } from './CollapsibleContainerHeader.types'

import { CollapsibleContainerContext } from '../../contexts/CollapsibleContainerContext'
import { CollapsibleContainerContextType } from '../../contexts/CollapsibleContainerContext.types'

import localThemes from '../../themes/CollapsibleContainer.theme'

export class CollapsibleContainerHeader extends PureComponent<CollapsibleContainerHeaderProps> {
  public static defaultProps: Partial<CollapsibleContainerHeaderProps> = {
    size: 2,
  }

  public static contextType = CollapsibleContainerContext

  public context: CollapsibleContainerContextType

  public render(): ReactNode {
    const appearance = localThemes(this.props.theme!).appearance[this.context.appearance!]
    return (
      <Styled.CollapsibleContainerHeader
        onClick={this.context.toggle}
        appearance={this.context.appearance}
        isInverse={this.context.isInverse}
        size={this.props.size}
        width={this.context.width}
      >
        <Styled.Children>{this.props.children}</Styled.Children>
        <Styled.IconContainer isActive={this.context.isActive}>
          <Icon
            appearance={this.context.isInverse ? appearance.inverse.iconAppearance : appearance.iconAppearance}
            inverse={this.context.isInverse ? appearance.inverse.inverseIcon : appearance.inverseIcon}
            name='chevron-down'
            size={3}
          />
        </Styled.IconContainer>
      </Styled.CollapsibleContainerHeader>
    )
  }
}

export default withTheme(CollapsibleContainerHeader)
