import { BaseFont } from '@vfuk/core-themes/dist/constants/baseFont.types'

import fontWeight from './fontWeight'
import typography from './typography'
import colors from './colors'

const baseFont: BaseFont = {
  fontSize: typography.paragraph[2].fontMobile,
  fontWeight: fontWeight[2],
  color: colors.monochrome6.default,
  backgroundColor: colors.monochrome2.default,
  fontFamily: 'Vodafone, Open Sans, sans-serif',
  lineHeight: typography.paragraph[2].lineHeightMobile,
}

export default baseFont
