import browserCookie from 'browser-cookies'

import { Req } from './getCookie.types'

/**
 * get cookie, both server and client side
 * @param name
 * @param req
 */
const getCookie = (name: string, req?: Req): string | null => {
  if (req) {
    return req.cookies[name]
  }

  return browserCookie.get(name)
}

export default getCookie
