import React, { Fragment, PureComponent, ReactNode } from 'react'
import { withTheme } from 'styled-components'
import Lottie from 'react-lottie'

import AnimationContainer from '@vfuk/core-animation-container'
import Heading from '@vfuk/core-heading'

import * as Styled from './styles/Loader.style'

import { LoaderProps, LoaderState } from './Loader.types'

import localThemes from './themes/Loader.theme'

import getAsset from './utils/getAsset'

export class Loader extends PureComponent<LoaderProps> {
  public state: LoaderState = {
    animationData: undefined,
  }

  public static defaultProps: Partial<LoaderProps> = {
    appearance: 'primary',
    inverse: false,
    size: 3,
    level: 1,
  }

  private async fetchAsset(): Promise<void> {
    try {
      const res = await getAsset(this.assetLocation)
      this.setState({ animationData: res })
    } catch {
      this.setState({ animationData: null })
    }
  }

  componentDidMount(): void {
    this.fetchAsset()
  }

  private get assetLocation(): string {
    const localTheme = localThemes(this.props.theme!)
    return this.props.inverse
      ? localTheme.appearance[this.props.level!][this.props.appearance!].inverse.asset
      : localTheme.appearance[this.props.level!][this.props.appearance!].asset
  }

  public render(): ReactNode {
    const animationOptions = {
      loop: true,
      autoplay: true,
      animationData: this.state.animationData,
    }

    const localTheme = localThemes(this.props.theme!)

    if (!this.state.animationData) return null

    return (
      <Fragment>
        <Styled.Loader>
          <AnimationContainer>
            <Styled.Spinner size={this.props.size}>
              <Lottie options={animationOptions} />
            </Styled.Spinner>
          </AnimationContainer>
          <If condition={this.props.text}>
            <Heading
              size={localTheme.size[this.props.size!].headingSize}
              justify='center'
              text={this.props.text}
              inverse={this.props.inverse}
            />
          </If>
        </Styled.Loader>
        <Styled.HiddenLabel>{this.props.srText}</Styled.HiddenLabel>
      </Fragment>
    )
  }
}

export default withTheme(Loader)
