import React, { FC, ReactElement } from 'react'

import * as Styled from './styles/Divider.style'

import { DividerProps } from './Divider.types'

const Divider: FC<DividerProps> = ({
  appearance = 'primary',
  orientation = 'horizontal',
  level = 1,
  inverse = false,
  noMargin = false,
}: DividerProps): ReactElement => {
  return <Styled.Divider appearance={appearance!} orientation={orientation} level={level} inverse={inverse!} noMargin={noMargin} />
}

export default Divider
