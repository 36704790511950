import { css, FlattenSimpleInterpolation } from 'styled-components'

import { FontFiles } from '@vfuk/core-themes/dist/constants/fonts.types'

const normalize = css`
  ${({ theme }): FlattenSimpleInterpolation => {
    return theme.fonts.fontFiles.map((fontFile: FontFiles): FlattenSimpleInterpolation => {
      return css`
        @font-face {
          font-family: '${theme.fonts.family}';
          src: local('${fontFile.file}'),
            url('${theme.baseAssetLocation}${theme.assetLocations.fonts}${fontFile.file}.${theme.fonts.extension}')
              format('${theme.fonts.extension}');
          font-style: normal;
          font-weight: ${fontFile.weight};
          font-display: swap;
        }
      `
    })
  }}
` as FlattenSimpleInterpolation

export default normalize
