import { css, FlattenSimpleInterpolation } from 'styled-components'

import { typography } from '@vfuk/core-mixins'

const inputElementSuggestions = css`
  ${({ theme }): FlattenSimpleInterpolation => css`
    input:-webkit-autofill::first-line {
      font-family: ${theme.baseFont.fontFamily};
      ${typography.paragraph(2, false)}
    }
  `}
` as FlattenSimpleInterpolation

export default inputElementSuggestions
