import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

export const AnimationContainer = styled.div((): FlattenSimpleInterpolation => css`
  position: relative;
  height: 100%;
`)

export const AnimationCover = styled.div((): FlattenSimpleInterpolation => css`
  position: absolute;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  background-color: red;
  z-index: 100;
  top: -3px;
  left: -3px;
`)
