import React, { ComponentClass, ComponentType, ReactElement } from 'react'

import { useBreakpoints } from '../useBreakpoints'

// HOC
export const withBreakpoints = <T,>(TargetComponent: ComponentClass<T> | ComponentType<T>): ComponentType<T> => {
  return (props: T): ReactElement => {
    const breakpoints = useBreakpoints()
    return <TargetComponent {...props} breakpoints={breakpoints} />
  }
}
