import React, { FC } from 'react'

import * as Styled from './styles/NumberBadge.style'

import { NumberBadgeProps } from './NumberBadge.types'

const NumberBadge: FC<NumberBadgeProps> = ({ appearance = 'primary', number }: NumberBadgeProps) => {
  const isLargeNumber = number > 999
  const isSmallNumber = number < 10
  return (
    <Styled.NumberBadge appearance={appearance} isSmallNumber={isSmallNumber}>
      <Styled.Number appearance={appearance}>
        <Choose>
          <When condition={isLargeNumber}>999+</When>
          <Otherwise>{Number(number)}</Otherwise>
        </Choose>
      </Styled.Number>
    </Styled.NumberBadge>
  )
}

export default NumberBadge
