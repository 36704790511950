import React, { ReactElement, FC } from 'react'

import * as Styled from './styles/Paragraph.style'

import { ParagraphProps } from './Paragraph.types'

const Paragraph: FC<ParagraphProps> = ({
  appearance = 'primary',
  inverse = false,
  size = 2,
  noMargin = false,
  justify,
  weight,
  children,
}: ParagraphProps): ReactElement => {
  return (
    <Styled.Paragraph size={size!} appearance={appearance} inverse={inverse} justify={justify} weight={weight} noMargin={noMargin!}>
      {children}
    </Styled.Paragraph>
  )
}

export default Paragraph
