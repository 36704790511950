import { IconRuleSet } from '@vfuk/core-icon-generator/dist/IconGenerator.types'

// Setting up nonThemeableRules for use within the React component generator
export const nonThemeableRules: IconRuleSet = {
  packageName: 'SourcePaymentIcons',
  defaultSize: 3,
  hasNumberBadge: false,
  size: {
    1: {
      sm: 16,
      md: 16,
      lg: 16,
      xl: 16,
    },
    2: {
      sm: 20,
      md: 20,
      lg: 20,
      xl: 20,
    },
    3: {
      sm: 20,
      md: 24,
      lg: 24,
      xl: 24,
    },
    4: {
      sm: 24,
      md: 32,
      lg: 32,
      xl: 32,
    },
    5: {
      sm: 32,
      md: 36,
      lg: 36,
      xl: 36,
    },
  },
}

const ruleSet = (): IconRuleSet => {
  return nonThemeableRules
}

export default ruleSet
