/* eslint-disable complexity, sonarjs/cognitive-complexity */
import styled, { FlattenSimpleInterpolation, css } from 'styled-components'

import { respondTo } from '@vfuk/core-mixins'

import { StyledSimpleGridProps } from './SimpleGrid.style.types'

type GridProps = Pick<StyledSimpleGridProps, 'theme' | 'spacing' | 'verticalAlign' | 'justify'>
export const SimpleGrid = styled.div((props: GridProps): FlattenSimpleInterpolation => {
  const spacingLevel = props.theme.spacing.responsive[props.spacing]
  return css`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: -${spacingLevel.sm / 2}px;

    ${respondTo.md(css`
      margin: -${spacingLevel.md / 2}px;
    `)}

    ${respondTo.lg(css`
      margin: -${spacingLevel.lg / 2}px;
    `)}

    ${respondTo.xl(css`
      margin: -${spacingLevel.xl / 2}px;
    `)}

    ${props.verticalAlign === 'center' &&
    css`
      align-items: center;
    `}

    ${props.verticalAlign === 'stretch' &&
    css`
      align-items: stretch;
    `}

    ${props.verticalAlign === 'bottom' &&
    css`
      align-items: flex-end;
    `}

    ${props.justify === 'center' &&
    css`
      justify-content: center;
    `}

    ${props.justify === 'right' &&
    css`
      justify-content: flex-end;
    `}
  `
})

type ColumnProps = Pick<StyledSimpleGridProps, 'theme' | 'spacing' | 'columns' | 'layout' | 'verticalAlign'>
export const SimpleGridColumn = styled.div((props: ColumnProps): FlattenSimpleInterpolation => {
  const spacingLevel = props.theme.spacing.responsive[props.spacing]
  return css`
    margin: ${spacingLevel.sm / 2}px;

    ${respondTo.md(css`
      margin: ${spacingLevel.md / 2}px;
    `)}

    ${respondTo.lg(css`
      margin: ${spacingLevel.lg / 2}px;
    `)}

    ${respondTo.xl(css`
      margin: ${spacingLevel.xl / 2}px;
    `)}

    ${props.columns &&
    css`
      width: calc((100% / ${props.columns.sm}) - ${spacingLevel.sm}px);

      ${respondTo.md(css`
        width: calc((100% / ${props.columns.sm}) - ${spacingLevel.md}px);

        ${props.columns.md &&
        css`
          width: calc((100% / ${props.columns.md}) - ${spacingLevel.md}px);
        `}
      `)}

      ${respondTo.lg(css`
        width: calc((100% / ${props.columns.sm}) - ${spacingLevel.lg}px);

        ${props.columns.md &&
        css`
          width: calc((100% / ${props.columns.md}) - ${spacingLevel.lg}px);
        `}

        ${props.columns.lg &&
        css`
          width: calc((100% / ${props.columns.lg}) - ${spacingLevel.lg}px);
        `}
      `)}

      ${respondTo.xl(css`
        width: calc((100% / ${props.columns.sm}) - ${spacingLevel.xl}px);

        ${props.columns.md &&
        css`
          width: calc((100% / ${props.columns.md}) - ${spacingLevel.xl}px);
        `}

        ${props.columns.lg &&
        css`
          width: calc((100% / ${props.columns.lg}) - ${spacingLevel.xl}px);
        `}

        ${props.columns.xl &&
        css`
          width: calc((100% / ${props.columns.xl}) - ${spacingLevel.xl}px);
        `}
      `)}
    `}

    ${props.layout &&
    css`
      width: calc(((100% / 12) * ${props.layout.sm}) - ${spacingLevel.sm}px);

      ${respondTo.md(css`
        width: calc(((100% / 12) * ${props.layout.sm}) - ${spacingLevel.md}px);

        ${props.layout.md &&
        css`
          width: calc(((100% / 12) * ${props.layout.md}) - ${spacingLevel.md}px);
        `}
      `)}

      ${respondTo.lg(css`
        width: calc(((100% / 12) * ${props.layout.sm}) - ${spacingLevel.lg}px);

        ${props.layout.md &&
        css`
          width: calc(((100% / 12) * ${props.layout.md}) - ${spacingLevel.lg}px);
        `}

        ${props.layout.lg &&
        css`
          width: calc(((100% / 12) * ${props.layout.lg}) - ${spacingLevel.lg}px);
        `}
      `)}

      ${respondTo.xl(css`
        width: calc(((100% / 12) * ${props.layout.sm}) - ${spacingLevel.xl}px);

        ${props.layout.md &&
        css`
          width: calc(((100% / 12) * ${props.layout.md}) - ${spacingLevel.xl}px);
        `}

        ${props.layout.lg &&
        css`
          width: calc(((100% / 12) * ${props.layout.lg}) - ${spacingLevel.xl}px);
        `}

        ${props.layout.xl &&
        css`
          width: calc(((100% / 12) * ${props.layout.xl}) - ${spacingLevel.xl}px);
        `}
      `)}
    `}

    ${props.verticalAlign === 'stretch' &&
    css`
      display: flex;

      & > * {
        flex-grow: 1;
      }
    `}
  `
})
