import React, { PureComponent, ReactNode } from 'react'

import getCookie from '@vfuk/core-helpers/dist/getCookie'
import isClient from '@vfuk/core-helpers/dist/isClient'

import { AnimationContainerProps, AnimationContainerState } from './AnimationContainer.types'

import * as Styled from './styles/AnimationContainer.style'

export default class AnimationContainer extends PureComponent<AnimationContainerProps, AnimationContainerState> {
  public static defaultProps: Partial<AnimationContainerProps> = {
    disabledCookieName: 'disableAnimation',
  }

  public state = {
    disableAnimation: this.shouldDisableAnimation(),
  }

  private shouldDisableAnimation(): boolean {
    if (isClient()) {
      return getCookie(this.props.disabledCookieName!) === 'true'
    }
    return false
  }

  public render(): ReactNode {
    return (
      <Choose>
        <When condition={this.state.disableAnimation}>
          <Styled.AnimationContainer>
            <Styled.AnimationCover />
            {this.props.children}
          </Styled.AnimationContainer>
        </When>
        <Otherwise>{this.props.children}</Otherwise>
      </Choose>
    )
  }
}
