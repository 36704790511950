import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import { spacing } from '@vfuk/core-mixins'

import { StyledCollapsibleContainerHeaderProps } from './CollapsibleContainerHeader.style.types'

import localThemes from '../../../themes/CollapsibleContainer.theme'

type HeaderProps = Pick<StyledCollapsibleContainerHeaderProps, 'theme' | 'appearance' | 'size' | 'isInverse' | 'width'>
export const CollapsibleContainerHeader = styled(Interaction)((props: HeaderProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  return css`
    width: ${props.width === 'auto' ? 'auto' : '100%'};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${localTheme.appearance[props.appearance!].color};
    text-align: inherit;
    position: relative;

    ${props.size === 2 &&
    css`
      font-size: ${props.theme.typography.heading[1].fontDesktop};
      font-weight: ${props.theme.fontWeight[3]};
    `}

    ${props.isInverse &&
    css`
      color: ${localTheme.appearance[props.appearance!].inverse.color};
    `}
  `
})

export const Children = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${spacing('margin-right', 3)};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
  `,
)

type IconProps = Pick<StyledCollapsibleContainerHeaderProps, 'isActive'>
export const IconContainer = styled.div(
  (props: IconProps): FlattenSimpleInterpolation => css`
    transition: 0.2s ease-out;
    min-height: 26px;
    display: flex;
    margin-left: auto;

    ${props.isActive &&
    css`
      transform: rotate(180deg);
    `}

    span {
      align-items: center;
    }
  `,
)
