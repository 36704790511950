import React, { ReactNode, ReactElement, PureComponent } from 'react'

import * as Styled from './styles/SimpleGrid.style'

import { SimpleGridProps, LayoutColumn, Layout } from './SimpleGrid.types'

export default class SimpleGrid extends PureComponent<SimpleGridProps> {
  public static defaultProps: Partial<SimpleGridProps> = {
    spacing: 0,
    justify: 'left',
  }

  public getLayout(index: number): LayoutColumn {
    const layoutProps = this.props.layout
    const layout: LayoutColumn = {}
    if (!layoutProps) return layout

    Object.keys(layoutProps).forEach((breakpoint: keyof Layout) => {
      const value = layoutProps[breakpoint] as number[]
      layout[breakpoint] = value[index % value.length]
    })
    return layout
  }

  public get filteredChildren(): ReactNode[] {
    const filteredChildren: ReactNode[] = []
    React.Children.forEach(this.props.children, (child) => {
      if (child) filteredChildren.push(child)
    })
    return filteredChildren
  }

  public render(): ReactNode {
    return (
      <Styled.SimpleGrid justify={this.props.justify!} spacing={this.props.spacing!} verticalAlign={this.props.verticalAlign!}>
        {this.filteredChildren.map((child: ReactNode, index: number): ReactElement | null => {
          return (
            <Styled.SimpleGridColumn
              key={index}
              columns={this.props.columns}
              spacing={this.props.spacing}
              verticalAlign={this.props.verticalAlign!}
              layout={this.getLayout(index)}
            >
              {child}
            </Styled.SimpleGridColumn>
          )
        })}
      </Styled.SimpleGrid>
    )
  }
}
